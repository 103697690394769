import React, { useState, useEffect, useRef } from 'react'
import './index.less'

const Home = (props) => {
    const { history, intl } = props
    const data = [
        'Service1Describution',
        'Service2Describution',
        'Service3Describution',
        'Service4Describution',
        'Service5Describution',
        'Service6Describution',
        'Service7Describution',
        'Service8Describution',
    ]
    return (
        <div id='service'>
            {
                data.map((item, index) => {
                    return (
                        <div className='serviceItem' key={index} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: item }) }} />
                    )
                })
            }
        </div>
    )
}

export default Home
