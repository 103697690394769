import React, { useState, useEffect } from 'react'
import './index.less'
import logo from '../../assets/images/logo.jpg'
import { MenuOutlined } from '@ant-design/icons'
import { Drawer, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const Header = (props) => {
    const { intl } = props
    const [visible, setVisible] = useState(false)
    const languageType = useSelector(state => state.languageType)
    const dispatch = useDispatch()
    const handleChangeLanguageType = () => {
        dispatch({
            type: 'changeLanguageType',
            languageType: languageType === 'zh_CN' ? 'en' : 'zh_CN'
        })
    }
    const menuList = [
        {
            title: intl.formatMessage({ id: 'AboutUs' }),
            subMenus: [
                { title: intl.formatMessage({ id: 'About-C_Margin' }), path: '/home' },
                { title: intl.formatMessage({ id: 'OurTeam' }), path: '/team' }
            ]
        },
        {
            title: intl.formatMessage({ id: 'OurServices' }),
            subMenus: [
                { title: intl.formatMessage({ id: 'ServiceContent' }), path: '/service' }
            ]
        }
    ]
    return (
        <div id='header'>
            <img src={logo} className='logo' />
            <div className='operaBar'>
                <Button type="primary" onClick={handleChangeLanguageType}>{languageType === 'zh_CN' ? 'En' : '中'}</Button>&nbsp;&nbsp;
                <MenuOutlined className='menuIcon' onClick={() => setVisible(true)} />
            </div>
            <Drawer
                title=""
                placement="right"
                className="menuDrawer"
                onClose={() => setVisible(false)}
                visible={visible}
                closable={false}
                getContainer='.operaBar'
            >
                <div className='menuContent'>
                    {
                        menuList.map(({ title, subMenus }, index) => {
                            return (
                                <div className='menuBlock' key={index}>
                                    <h3 className='title'>{title}</h3>
                                    <div className='subMenuList'>
                                        {
                                            subMenus.map(({ title, path }) => {
                                                return (
                                                    <div className='subMenuItem' key={title}>
                                                        <Link to={path}>{title}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='maskContent'>
                    <span>{intl.formatMessage({ id: 'TapHere' })}</span>
                    <span>{intl.formatMessage({ id: 'CloseDirectory' })}</span>
                </div>
            </Drawer>
        </div>
    )
}

export default Header
