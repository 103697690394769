/* eslint-disable */
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from './router/routes'
import './App.less';
import Header from './components/header';
import { useIntl } from 'react-intl'

function App() {
  const intl = useIntl()

  return (
    <BrowserRouter>
      <Header intl={intl} />
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        {renderRoutes(routes, { intl })}
      </Switch>
      <a href='https://beian.miit.gov.cn/#/Integrated/index' className='copyRight'>沪ICP备2022002565号-1</a>
    </BrowserRouter>
  );
}

export default App;
