import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import store from './store'
import RootContainer from './components/rootContainer';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RootContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

