/* eslint-disable */
import Home from '@/pages/home'
import Team from '@/pages/team'
import Service from '@/pages/service'

const routers = [
  {
    path: "/home",
    component: Home,
    name: 'Home',
    exact: true
  },
  {
    path: "/team",
    component: Team,
    name: 'Team',
    exact: true
  },
  {
    path: "/service",
    component: Service,
    name: 'Service',
    exact: true
  },
]

export default routers