
const reducer = (state, action) => {
  switch (action.type) {
    case 'changeLanguageType':
      return { ...state, languageType: action.languageType }
    default:
      return state
  }
}

export default reducer
