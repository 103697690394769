import React, { useState, useEffect, useRef } from 'react'
import './index.less'
import { Carousel } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons'
import carouselImage1 from '../../assets/images/banner1.jpg'
import carouselImage2 from '../../assets/images/banner2.jpg'
import carouselImage3 from '../../assets/images/banner3.jpg'
import carouselImage4 from '../../assets/images/banner4.jpg'

const Home = (props) => {
    const { history, intl } = props
    const carouselRef = useRef()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [autoplay, setAutoplay] = useState(true)
    useEffect(() => {
        let startY, endY
        document.addEventListener('touchstart', e => {
            setAutoplay(false)
            startY = e.changedTouches[0].pageY
        }, false)
        document.addEventListener('touchmove', e => {
            e.preventDefault();
        })
        document.addEventListener('touchend', e => {
            endY = e.changedTouches[0].pageY
            let up_down = endY - startY
            if (up_down > 10) {
                carouselRef.current && carouselRef.current.prev()
            } else if (up_down < -10) {
                carouselRef.current && carouselRef.current.next()
            }
            startY = null
            endY = null
        }, false)
    }, [])
    return (
        <div id='home'>
            <Carousel
                autoplaySpeed={10000}
                infinite={false}
                dotPosition='right'
                autoplay={autoplay}
                dots={false}
                afterChange={current => setCurrentIndex(current)}
                ref={node => carouselRef.current = node}
            >
                <div className='carouselItem'>
                    {
                        currentIndex === 0 ? (
                            <React.Fragment>
                                <img src={carouselImage1} />
                                <span className='des animate__animated animate__fadeInUp animate__delay-1s'>
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Banner1Describution' }) }}></span>
                                </span>
                                <DoubleRightOutlined
                                    rotate={90}
                                    className='arrow animate__animated animate__slideInDown animate__infinite'
                                />
                            </React.Fragment>
                        ) : null
                    }
                </div>
                <div className='carouselItem'>
                    {
                        currentIndex === 1 ? (
                            <React.Fragment>
                                <img src={carouselImage2} />
                                <span className='des animate__animated animate__fadeInUp animate__delay-1s'>
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Banner2Describution' }) }}></span>
                                </span>
                                <DoubleRightOutlined
                                    rotate={90}
                                    className='arrow animate__animated animate__slideInDown animate__infinite'
                                />
                            </React.Fragment>
                        ) : null
                    }
                </div>
                <div className='carouselItem'>
                    {
                        currentIndex === 2 ? (
                            <React.Fragment>
                                <img src={carouselImage3} />
                                <span className='des animate__animated animate__fadeInUp animate__delay-1s'>
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Banner3Describution' }) }}></span>
                                </span>
                                <DoubleRightOutlined
                                    rotate={90}
                                    className='arrow animate__animated animate__slideInDown animate__infinite'
                                />
                            </React.Fragment>
                        ) : null
                    }
                </div>
                <div className='carouselItem'>
                    {
                        currentIndex === 3 ? (
                            <React.Fragment>
                                <img src={carouselImage4} />
                                <span className='des animate__animated animate__fadeInUp animate__delay-1s'>
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Banner4Describution' }) }}></span>
                                </span>
                                <DoubleRightOutlined
                                    rotate={90}
                                    className='arrow animate__animated animate__slideInDown animate__infinite'
                                />
                            </React.Fragment>
                        ) : null
                    }
                </div>
            </Carousel>
        </div>
    )
}

export default Home
