/* eslint-disable */
const zh_CN = {
    LanguageType: '中',
    AboutUs: '关于我们',
    'About-C_Margin': '关于创边际',
    OurTeam: '我们的团队',
    QRCode: '公众号二维码',
    TalentRecruitment: '人才招聘',
    OurServices: '我们的服务',
    ServiceContent: '服务内容',
    TapHere: '轻触这里',
    CloseDirectory: '关闭目录',
    WithUs: '和我们一起',
    FinancialIssues: '财务问题',
    SoEasy: '如此简单',
    ProfessionalPeople: '专业人员',
    ChineseCertifiedPublicAccountant: '中国注册会计师',
    InTheProcessOfClimbingThePeak: '勇攀高峰的过程中',
    LetUsForYou: '让我们为您免去',
    FinancialWorries: '财务后顾之忧',
    GlobalLayout: '全球布局',
    HelpYouToGoOut: '助您更好的走出去',
    CoverCountrys: '个国家覆盖',
    EnterpriseInvestedInChinaFromWord: '个国家来华投资的企业',

    // banner图的描述
    Banner1Describution: `服务来自<span class='num'>20+</span>国家来华投资的企业`,
    Banner2Describution: `服务超过<span class='num'>100+</span>不同类型的投资企业`,
    Banner3Describution: `专业的财务服务<br>无关乎企业来自哪里<br>规模多大<br>我们告诉你需要做什么<br>怎么做`,
    Banner4Describution: `和我们在一起<br>拥有您专属的CFO<br>让财务、投资、融资、税收<br>变得简单明了`,

    // 我们的服务
    Service1Describution: `<span class='title'>*初创时的股权构架设立</span><br>很多创业投资者，对公司主体初始设置是的股权架构一知半解，不太明确那种方式最契合自己的关心，最符合自己的行业。所以创边际再最初的公司成立前就会提供咨询服务，帮助分析最佳的股权架构方案。`,
    Service2Describution: `<span class='title'>*国内公司的注册，海外离岸公司的设立</span><br>创边际提供国内公司以及海外离岸公司的设立一条龙服务，直至您的公司准备收付款以及开具发票等等一切准备工作。`,
    Service3Describution: `<span class='title'>*财务体系的设立，财务管理软件的支持</span><br>如果您是我们的虚拟财务总监服务的客户，我们也会提供实施财务软件的系统性服务，让中小企业也能拥有大企业的管理水平。`,
    Service4Describution: `<span class='title'>*集团的财务日常跟踪已经定期多维度报表</span><br>创边际作为国内罕有的高端财务咨询服务的提供者，远远不在局限于传统标准账的有限的服务，我们更擅长提供属于中小企业自己的专业高端的财务服务体验，管理者对公司最核心的财务状况更加系统性的了解，我们可以根据不同行业，企业自身的不同情况，设计，提供多维度的财务管理数据。`,
    Service5Describution: `<span class='title'>*人力资源成本管理</span><br>很多中小企业的成本管理当中，人力资源成本管理也是其中非常重要的一部分，创边际作为您的虚拟管理层，也会提供相应的管理支持，比如人力成本核算，人力成本的合理设置， 以及如何优化人力成本等等。`,
    Service6Describution: `<span class='title'>*税收筹划</span><br>创边际的客户来自世界各地，各行各业。我们结合对中国的税法的熟悉，以及各不同税收契约国家之间的政策的了解等等因素，更加合理合法的给到中小企业税收建议。帮助做到最合理的税收筹划。`,
    Service7Describution: `<span class='title'>*资金流管控</span><br>作为创边际的虚拟财务总监服务客户，我们把对现金流的管理作为所有财务管理的重中之重。`,
    Service8Describution: `<span class='title'>*股东会议的财报准备以及会议支持</span><br>如果您是我们的虚拟财务总监服务的客户，我们也会提供相关管理层会议的财务数据支持以及对财务情况的解释解读。这是虚拟财务总监服务最为重要的体验部分。`,

    // 我们的团队
    team_NORA_CHEN: '创始人，超过10年的国际会计师经验，国家会计师，英国ACCA会员，擅长中小企业的财务构架设立，成本管理，内控审计，跨境的股权架构设立，离岸公司的设计成立，税务筹划，项目成本谈判。',
    team_ADA_CHEN: 'C Margin合伙人，超过10年的财务管理经验，国家会计师，擅长中小企业财务构架设立，成本管理，内控审计,预算管理，税务筹划，各类财务软件的实施。',
    team_Martin_H: '注册会计师CPA,高级财务顾问，超过10年的财务管理经验，多年的跨国企业的工作经验，成本管理，税务筹划。擅长合伙企业上层架构的设计，落地。',
    team_ALICE_YE: '高级财务顾问、国家会计师，超过10年的财务管理经验，多年的跨国企业的工作经验，成本管理，税务筹划。',
    team_KURUPPATH_SUGUNAN_SANEESHMA: '高级财务顾问，MBA, 擅长各类财务报告的出具，企业内部审计，预算，成本管理，客户关系管理。',
    team_WanQing_Li: '财务顾问，擅长基础数据的收集整理，项目实施，数据追踪。',

}

export default zh_CN