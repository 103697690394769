import React from 'react';
import App from '@/App';
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import ant_zhCN from 'antd/lib/locale/zh_CN'
import ant_en from 'antd/lib/locale/en_US'
import self_zhCN from '@/locales/zh_CN'
import self_en from '@/locales/en'
import { useSelector } from 'react-redux'

const RootContainer = () => {
    const languageType = useSelector(state => state.languageType)
    const antLocal = languageType === 'zh_CN' ? ant_zhCN : ant_en
    const selfLocal = languageType === 'zh_CN' ? self_zhCN : self_en
    const intlProviderLocal = languageType === 'zh_CN' ? 'zh' : 'en'

    return (
        <IntlProvider locale={intlProviderLocal} messages={selfLocal} key={languageType}>
            <ConfigProvider locale={antLocal}>
                <App />
            </ConfigProvider>
        </IntlProvider>
    )
}

export default RootContainer
