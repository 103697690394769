import React, { useState, useEffect } from 'react'
import './index.less'

import NORA_CHEN from '../../assets/images/NORA_CHEN.jpeg'
import ADA_CHEN from '../../assets/images/ADA_CHEN.png'
import Martin_H from '../../assets/images/Martin_H.png'
import ALICE_YE from '../../assets/images/ALICE_YE.png'
import KURUPPATH_SUGUNAN_SANEESHMA from '../../assets/images/KURUPPATH_SUGUNAN_SANEESHMA.jpeg'
import WanQing from '../../assets/images/WanQing2.jpeg'


const Team = (props) => {
    const { history, intl } = props
    const teamData = [
        {
            name: 'NORA CHEN',
            url: NORA_CHEN,
            describe: intl.formatMessage({ id: 'team_NORA_CHEN' })
        },
        {
            name: 'Ada Chen',
            url: ADA_CHEN,
            describe: intl.formatMessage({ id: 'team_ADA_CHEN' })
        },
        {
            name: 'Martin H',
            url: Martin_H,
            describe: intl.formatMessage({ id: 'team_Martin_H' })
        },
        {
            name: 'ALICE YE',
            url: ALICE_YE,
            describe: intl.formatMessage({ id: 'team_ALICE_YE' })
        },
        {
            name: 'SANEESHMA',
            url: KURUPPATH_SUGUNAN_SANEESHMA,
            describe: intl.formatMessage({ id: 'team_KURUPPATH_SUGUNAN_SANEESHMA' })
        },
        {
            name: 'WanQing',
            url: WanQing,
            describe: intl.formatMessage({ id: 'team_WanQing_Li' })
        }
    ]
    return (
        <div id='Team'>
            <div className='title'>{intl.formatMessage({ id: 'OurTeam' })}</div>
            {
                teamData.map((item, index) => {
                    const { name, url, job, describe } = item
                    return (
                        <div className='member' key={index}>
                            <img src={url} />
                            <div className='name'>{name}</div>
                            <div className='describution'>{describe}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Team
