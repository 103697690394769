import { createStore } from 'redux'
import rootReducer from './redux'

// 预先定义的状态
const preloadedState = {
  languageType: 'zh_CN'
}

// 初始化store
const store = createStore(
  rootReducer,
  preloadedState
)

export default store
