/* eslint-disable */
const en = {
    LanguageType: 'En',
    AboutUs: 'About Us',
    'About-C_Margin': 'About C-Margin',
    OurTeam: 'Our Team',
    QRCode: 'QRCode',
    TalentRecruitment: 'TalentRecruitment',
    OurServices: 'Our Services',
    ServiceContent: 'C-Margin Services',
    TapHere: 'Tap here',
    CloseDirectory: 'Close directory',

    // banner图的描述
    Banner1Describution: `Service for <span class='num'>20+</span> countries’ investors from all over the world`,
    Banner2Describution: `Service for <span class='num'>100+</span>WOFE clients and Local companies in China `,
    Banner3Describution: `Professional CFO Services<br>We can be your lead of setting up a real finance system for your company`,
    Banner4Describution: `Work with C-margin, have your own CFO even you are SME, to make the accounting, investment, tax clean and financing easy and organized`,

    // 我们的服务
    Service1Describution: `<span class='title'>*The SME equity framework</span><br>Many venture investors have limited knowledge of equity structure in the initial setting up of the company, and are not very clear about the way that fits their needs most and their own industry most. C-margin provides consulting services of shares set-up before the SEM is founded, to help analyze the best equity structure.`,
    Service2Describution: `<span class='title'>*The establishment of offshore companies and WOFE in China</span><br>C-margin provides one-stop service for both WOFE and offshore companies, till it’s ready for payments in and out, and invoices/fapiao issuing in and out.`,
    Service3Describution: `<span class='title'>*Finance system set-up, and accounting soft ware supporting</span><br>As your virtual CFO, we will also provide financial software set up supporting, to help you to get into the big company’s management level.`,
    Service4Describution: `<span class='title'>*Regular muti-dimensional financial statements</span><br>To help the owner have a systematic understanding of the company. C-margin as a virtual CFO service provider, is good at customizing analytical data according to SME’s size and industry etc.`,
    Service5Describution: `<span class='title'>*HR cost Management</span><br>Many clients of C-magin, HR cost is a big part of the cost map. We also help to provide supporting for HR cost layout, calculation and optimize the human resource cost.`,
    Service6Describution: `<span class='title'>*Tax balancing</span><br>C-margin customers are from all over the world, we can help to balance your business tax according to China's tax system , and help to achieve the most reasonable tax planning.`,
    Service7Describution: `<span class='title'>*Cash flow management</span><br>As your virtual CFO service provider, we treat your cash flow management as one of the most important points.`,
    Service8Describution: `<span class='title'>*Shareholders meeting supports</span><br>As your virtual CFO, we provide finance data supporting, to help you to have a whole understanding of the company business EBITDA and all analytical data you need. This is one of the best parts of our virtual CFO service experience.`,

    // 我们的团队
    team_NORA_CHEN: 'The founder, who has 12+ years senior international accounting experience, Senior Certificated National Accountant, ACCA Member, is good at setting up finance system for SME with good knowledge of international accounting rules, cost management, internal audit, international investors structure layout, offshore companies set-up, global tax balancing and projects pricing.',
    team_ADA_CHEN: 'C-margin partner, who has 13+ years senior international accounting experience, Senior Certificated National Accountants, is good at setting up finance system for SME with good knowledge of cost management, internal audit, international investors structure layout, offshore companies set-up, global tax balancing, cash flow budget and projects pricing.',
    team_Martin_H: 'CPA, senior finance consultant, who has 12+ years finance consulting experience. Martin has been working for many of our clients’ training projects, to help them with a better understanding of China investment environment, how to set up your business in China, and how and where to begin. Martin is really good at company shareholders’ structural set-up.',
    team_ALICE_YE: 'Senior accountant、national accountant，who has been working in different international companies for many years and she is also senior certificated national accountant. Alice is good at China tax system, SME cost management.',
    team_KURUPPATH_SUGUNAN_SANEESHMA: 'MBA, senior finance consultant, who has 6+ years consulting experience. Saneeshma is really good at data base set up and layout for the finance reports, internal audit, cash flow budget, cost management and CRM.',
    team_WanQing_Li: 'Senior Accountant, who has been working with C-margin for years, Wanqing is good at database collecting and organizing, Project implementation and follow ups. Wanqing is now also working on the national senior certificated accountant.',
}

export default en